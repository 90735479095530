import { useContext } from "react";

import Form from "Atoms/Form";
import { useVehicleStore } from "Clutch/Stores/VehicleStore"
import { NavigationContext } from "Contexts/Navigation/NavigationContext";

import HeroSectionText from "../../hero-section-text/HeroSectionText";
import styles from "./MobileYmmSelector.module.scss";

const MobileYmmSelector = ({ suppressOtherFitment, audience }) => {

  const { setYmmModal } = useContext(NavigationContext);
  const setCurrentTab = useVehicleStore(x => x.setCurrentTab)
  return (
    <div className={styles.wrapper}>
      {suppressOtherFitment && audience !== 'race' || !suppressOtherFitment ? <>
        <Form.Select className={styles.select} placeholder={"1. Choose Year"} aria-label={'Choose Year'} displayLabel={false}></Form.Select>
        <Form.Select className={styles.select} placeholder={"2. Choose Make"} aria-label={'Choose Make'} displayLabel={false}></Form.Select>
        <Form.Select className={styles.select} placeholder={"3. Choose Model"} aria-label={'Choose Model'} displayLabel={false}></Form.Select>
      </>
        : null
      }

      {suppressOtherFitment ? null :
        <HeroSectionText
          className={styles.raceText}
          text={"or Race"}
          size={1.5}
          bold
        />
      }
      {suppressOtherFitment && audience === 'race' || !suppressOtherFitment ?
        <Form.Select placeholder={"Choose your Race Car"}  aria-label={'Choose your Race Car'} displayLabel={false}></Form.Select>
        : null
      }
      {suppressOtherFitment ?
        <div
          className={styles.allOverlay}
          onClick={() => {
            setCurrentTab(audience === 'race' ? audience : "street");
            setYmmModal(true);
          }}
        />
        :
        <>
          <div
            className={styles.streetOverlay}
            onClick={() => {
              setCurrentTab("street");
              setYmmModal(true, 'street');
            }}
          />

          <div
            className={styles.raceOverlay}
            onClick={() => {
              setCurrentTab("race");
              setYmmModal(true, 'race');
            }}
          />

        </>
      }
    </div>


  );
};

export default MobileYmmSelector;
