import { createContext, useState, useContext, useEffect } from "react";

import { useVehicleStore } from "Clutch/Stores/VehicleStore"

import { useHomePage } from "./HomePageProvider";

const fitmentStates = {
  vehicleSelected: "VEHICLE_SELECTED",
  noVehicleSelected: "NO_VEHICLE_SELECTED",
  noVehiclesInGarage: "NO_VEHICLES_IN_GARAGE",
};

const FitmentStateContext = createContext();

const FitmentStateProvider = ({ children }) => {
  const setCurrentTab = useVehicleStore(x => x.setCurrentTab);
  const hydrated = useVehicleStore(x => x.context.hydrated);
  const customerVehicles = useVehicleStore(x => x.context.customerVehicles);
  const selectedVehicleState = useVehicleStore(x => x.context.selectedVehicle);
  const { hasCustomerProjects } = useHomePage();
  const [fitmentState, setFitmentStates] = useState(
    hasCustomerProjects ? fitmentStates.noVehicleSelected : fitmentStates.noVehiclesInGarage
  );
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [isFindPartsClicked, setIsFindPartsClicked] = useState(false);

  useEffect(() => {
    if (hydrated) {
      if (customerVehicles?.length > 0) {
        const selectedRaceTypeId = selectedVehicleState?.raceTypeId;
        const selectedVehicleBaseId =
          selectedVehicleState?.vehicleBaseId;

        if (selectedRaceTypeId || selectedVehicleBaseId) {
          setSelectedVehicle(selectedVehicleState);
          setFitmentStates(fitmentStates.vehicleSelected);
        } else {
          setFitmentStates(fitmentStates.noVehicleSelected);
        }
      } else {
        setFitmentStates(fitmentStates.noVehiclesInGarage);
      }
    }
  }, [
    hydrated,
    customerVehicles,
    selectedVehicleState,
    setSelectedVehicle,
    setFitmentStates,
  ]);

  return (
    <FitmentStateContext.Provider
      value={{
        fitmentState,
        selectedVehicle,
        isFindPartsClicked,
        setIsFindPartsClicked,
        setCurrentTabForAudience: (audience) => {
          setCurrentTab(audience === "race" ? audience : "street");
        },
      }}
    >
      {children}
    </FitmentStateContext.Provider>
  );
};

const useFitmentStateContext = () => {
  const context = useContext(FitmentStateContext);
  return context;
};

export { FitmentStateProvider, useFitmentStateContext, fitmentStates };
