import React, { useContext, useEffect, useRef, useState } from "react";

import { useUserStore } from "@speedwaymotors/clutch/Stores/UserStore/UserStore";
import { IsUserBot } from "@speedwaymotors/clutch/Utilities/CookieUtilties/UserCookieUtilities";
import AutoComplete from "Clutch/Atoms/Form/Elements/AutoComplete/AutoComplete";
import Typography from "Clutch/Atoms/Typography";
import {
  AddUserVehicle,
  FetchYearMakeModel,
  getProperUrl,
} from "Clutch/Contexts/VehicleContext/CoreVehicleRequests";
import useSegment from "Clutch/Hooks/useSegment/useSegment";
import Button from "Clutch/Molecules/Button";
import { useThemeStore } from "Clutch/Stores/ThemeStore";
import { useVehicleStore } from "Clutch/Stores/VehicleStore";
import { createFitmentAddedSegmentEvent } from "Clutch/Utilities/Instrumentation/Fitment/fitmentSegmentEvents";
import { createClickedSegmentEvent } from "Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents";
//dont import dynamically
import { NavigationContext } from "Contexts/Navigation/NavigationContext";
import ClassNames from "classnames";

import { useFitmentStateContext } from "../../../../../providers/FitmentStateProvider";
import styles from "../YmmSelector.module.scss";

const initialStreetVehicleState = {
  year: {
    value: null,
    label: null,
  },
  make: {
    value: null,
    label: null,
  },
  model: {
    value: null,
    label: null,
  },
};

const StreetTab = ({ isEnabled }) => {
  const SelectYearMakeModel = useVehicleStore((x) => x.SelectYearMakeModel);
  const UpdateYearMakeModelData = useVehicleStore(
    (x) => x.UpdateYearMakeModelData
  );
  const selectedFacetData = useVehicleStore((x) => x.context.selectedFacetData);
  const models = useVehicleStore((x) => x.context.models);
  const reloadCurrentLocation = useVehicleStore(
    (x) => x.context.reloadCurrentLocation
  );
  const makes = useVehicleStore((x) => x.context.makes);
  const prioritizedMakes = useVehicleStore((x) => x.context.prioritizedMakes);
  const allYears = useVehicleStore((x) => x.context.allYears);
  const state = useVehicleStore((x) => x.context);
  const updateUserGuid = useUserStore((x) => x.context.updateUserGuid);
  const userGuid = useUserStore((x) => x.context.userGuid);
  const setIsLoading = useThemeStore((state) => state.setIsLoading);
  const [streetVehicleState, setStreetVehicleState] = useState(
    initialStreetVehicleState
  );
  const { state: navigationState } = useContext(NavigationContext);
  const { garage } = navigationState;
  const { isYmmModalOpen } = garage;
  const { sendCustomSegmentTrackEvent } = useSegment();
  const [errorState, setErrorState] = useState("");
  const { isFindPartsClicked, setIsFindPartsClicked } =
    useFitmentStateContext();

  useEffect(() => {
    if (isFindPartsClicked && isEnabled) {
      setIsFindPartsClicked(false);
      handleButtonClick();
    }
  }, [isFindPartsClicked, isEnabled]);

  useEffect(() => {
    const { year, make, model } = streetVehicleState;
    async function fetchAllYMM() {
      var response = await FetchYearMakeModel(
        state,
        year.value,
        make.value,
        model.value,
        "/graphql/trait",
        selectedFacetData
      );

      if (response) {
        if (!model.value) {
          UpdateYearMakeModelData(response.ymm);
        }
        let selectedModel = models
          ? models.find((x) => x.optionId === model.value)
          : [];
        if (!IsUserBot()) {
          UpdateUsersGarage(
            year.value,
            make.value,
            make.label,
            model.value,
            model.label,
            userGuid,
            "/graphql/customerproject",
            reloadCurrentLocation,
            selectedModel,
            SelectYearMakeModel,
            updateUserGuid,
            setIsLoading,
            selectedFacetData,
            sendCustomSegmentTrackEvent
          );
        }
      }
    }
    fetchAllYMM();
  }, [isEnabled, streetVehicleState, allYears]);

  const yearRef = useRef();
  const makeRef = useRef();
  const modelRef = useRef();

  useEffect(() => {
    if (
      makeRef?.current &&
      !isYmmModalOpen &&
      !streetVehicleState?.make?.value
    ) {
      makeRef.current.focus();
    }
  }, [makes]);

  useEffect(() => {
    if (modelRef?.current && !isYmmModalOpen) {
      modelRef.current.focus();
    }
  }, [models]);

  useEffect(() => {
    if (streetVehicleState?.year?.value && errorState === "year") {
      setErrorState("");
    }
  }, [errorState, streetVehicleState?.year?.value]);

  useEffect(() => {
    if (streetVehicleState?.make?.value && errorState === "make") {
      setErrorState("");
    }
  }, [errorState, streetVehicleState?.make?.value]);

  useEffect(() => {
    if (streetVehicleState?.model?.value && errorState === "model") {
      setErrorState("");
    }
  }, [errorState, streetVehicleState?.model?.value]);

  const handleButtonClick = () => {
    if (
      streetVehicleState?.year?.value === null &&
      yearRef?.current &&
      !isYmmModalOpen
    ) {
      yearRef.current.focus();
      setErrorState("year");
    } else if (
      streetVehicleState?.make?.value === null &&
      makeRef?.current &&
      !isYmmModalOpen
    ) {
      makeRef.current.focus();
      setErrorState("make");
    } else if (
      streetVehicleState?.model?.value === null &&
      modelRef?.current &&
      !isYmmModalOpen
    ) {
      modelRef.current.focus();
      setErrorState("model");
    }
  };

  return (
    <div className={styles.modalPanel}>
      <div className={ClassNames(styles.streetTab, styles.ymmContainer)}>
        <YmmDropdown
          placeholder={"Select a Year"}
          label={"Year"}
          className={styles.dropdownTitle}
          selectedValue={streetVehicleState.year}
          dropdownData={allYears}
          onChange={(option) => {
            setStreetVehicleState((prevState) => ({
              ...initialStreetVehicleState,
              year: {
                value: parseInt(option.value, 10),
                label: option.label,
              },
            }));
          }}
          selectRef={yearRef}
          isErrorState={errorState === "year"}
        />
        <YmmDropdown
          placeholder={"Select a Make"}
          label={"Make"}
          selectedValue={streetVehicleState.make}
          dropdownData={makes}
          className={styles.dropdownTitle}
          onChange={(option) => {
            setStreetVehicleState((prevState) => ({
              ...initialStreetVehicleState,
              year: prevState.year,
              make: {
                value: parseInt(option.value, 10),
                label: option.label,
              },
            }));
          }}
          prioritizedMakes={prioritizedMakes}
          selectRef={makeRef}
          isErrorState={errorState === "make"}
        />
        <YmmDropdown
          placeholder={"Select a Model"}
          label={"Model"}
          selectedValue={streetVehicleState.model}
          className={styles.dropdownTitle}
          dropdownData={models}
          onChange={(option) => {
            setStreetVehicleState((prevState) => ({
              ...prevState,
              model: {
                value: parseInt(option.value, 10),
                label: option.label,
              },
            }));
          }}
          selectRef={modelRef}
          isErrorState={errorState === "model"}
        />
        <div className={styles.ymmModalItem}>
          <Button
            className={styles.addVehicleButton}
            segmentEvent={createClickedSegmentEvent("OpenYmmModalHomePage")}
            brand={"secondary"}
            fill
            size={"small"}
            type={"button"}
            onClick={handleButtonClick}
          >
            <Typography
              className={styles.buttonText}
              tone={"contrast"}
              size={0.75}
              font={"bold"}
              inline
              data-testid={"findPartsStreet"}
            >
              Find Parts
            </Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};

const YmmDropdown = ({
  label,
  placeholder,
  selectedValue,
  dropdownData,
  onChange,
  prioritizedMakes,
  selectRef,
  isErrorState,
}) => {
  let options = [];

  if (prioritizedMakes?.length > 0) {
    prioritizedMakes.forEach((data) => {
      options.push({
        label: data.displayName,
        value: data.optionId,
      });
    });
  }

  let emptyStyling = "";

  if (dropdownData?.length > 0) {
    dropdownData.forEach((data) => {
      options.push({
        label: data.displayName,
        value: data.optionId,
      });
    });
  } else {
    emptyStyling = styles.streetEmptyDropdown;
  }

  var isDisabled = !dropdownData || dropdownData.length === 0;

  return (
    <div
      className={`${ClassNames(styles.ymmModalItem, emptyStyling)} ${
        isDisabled ? "" : `lr-${label}Selector`
      }`}
    >
      <AutoComplete
        options={options}
        onChange={(value) => onChange(value)}
        label={label}
        selectedValue={selectedValue}
        isDisabled={isDisabled}
        placeholder={placeholder}
        ref={selectRef}
        isErrorState={isErrorState}
        useNativeSelect={false}
      />
    </div>
  );
};

async function UpdateUsersGarage(
  year,
  makeId,
  make,
  modelId,
  model,
  currentUserGuid,
  serviceUrl,
  reloadCurrentLocation,
  selectedModel,
  SelectYearMakeModel,
  updateUserGuid,
  setIsLoading,
  selectedFacetData,
  sendCustomSegmentTrackEvent
) {
  if (year === null || makeId === null || modelId === null) {
    return;
  }

  try {
    setIsLoading(true);

    var response = await AddUserVehicle(
      currentUserGuid,
      serviceUrl,
      year,
      makeId,
      modelId,
      null,
      null,
      selectedFacetData
    );

    if (!response) {
      setIsLoading(false);
    }

    if (
      response?.addNewProjectToGarage?.success &&
      !response?.addNewProjectToGarage?.alreadyExisted
    ) {
      const segmentEvent = createFitmentAddedSegmentEvent(
        makeId,
        make,
        modelId,
        model,
        year,
        null,
        null
      );
      sendCustomSegmentTrackEvent(segmentEvent);
    }

    await SelectYearMakeModel(
      year,
      makeId,
      modelId,
      response?.addNewProjectToGarage?.projects
    );
    // if no user don't continue
    if (response?.addNewProjectToGarage?.webUser?.userGuid) {
      updateUserGuid(response?.addNewProjectToGarage?.webUser?.userGuid);
    } else {
      console.error("no web user guid " + JSON.stringify(response));
    }

    if (reloadCurrentLocation) {
      // return false or it skips to line below
      window.location.reload();
      return false;
    }

    window.location.href = getProperUrl(selectedModel);
  } catch (err) {
    console.error("Unable to Update Users Garage", err, year, makeId, modelId);
    setIsLoading(false);
  }
}
export default StreetTab;
