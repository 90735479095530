const streetCallout = {
  title: "STREET PRODUCTS",
  subtitle: "",
  image:
    "https://content.speedwaymotors.com/HomePageImages/Feature-Card-Street.png",
  button: {
    text: "Shop All Street",
    url: "/street",
  },
  bottomLinks: [
    {
      text: "Hot Rod",
      url: "/hot-rod",
    },
    {
      text: "Classic Truck",
      url: "/classic-truck",
    },
    {
      text: "Muscle Car",
      url: "/muscle-car",
    },
    {
      text: "T-Bucket",
      url: "/t-bucket",
    },
  ],
  appName: "HomePage",
};

const raceCallout = {
  title: "RACE PRODUCTS",
  subtitle: "",
  image:
    "https://content.speedwaymotors.com/HomePageImages/Feature-Card-Race.png",
  button: {
    text: "Shop All Race",
    url: "/race",
  },
  bottomLinks: [
    {
      text: "Oval Track",
      url: "/oval-track",
    },
    {
      text: "Drag Racing",
      url: "/drag-racing",
    },
    {
      text: "Demolition Derby",
      url: "/demolition-derby",
    },
  ],
  appName: "HomePage",
};

const engineCallout = {
  title: "RACING ENGINES",
  subtitle: "Full Service Engine Shop",
  image:
    "https://content.speedwaymotors.com/HomePageImages/Racing-engine-card.png",
  button: {
    text: "Engine Machine Shop",
    url: "/Featured/Racing-Engines",
  },
  appName: "HomePage",
  noSubtitleOnMobile: true
};

const shockCallout = {
  title: "SHOCKS",
  subtitle: "Quick Turnaround on Rebuilds",
  image: "https://content.speedwaymotors.com/HomePageImages/Shocks-card.png",
  button: {
    text: "Shock Rebuilding Service",
    url: "/Info/Shock",
  },
  appName: "HomePage",
  noSubtitleOnMobile: true
};

const catalogCallout = {
  title: "REQUEST A CATALOG",
  subtitle: "Street | Muscle Car | Race | Open Wheel",
  noSubtitleOnMobile: true,
  image:
    "https://content.speedwaymotors.com/HomePageImages/FEATURED-CARD-CATALOG.png",
  button: {
    text: "Order Yours Now",
    url: "/Catalog/Request",
  },
  appName: "HomePage",
  darkened: true,
};

const giftcardCallout = {
  title: "GIFT CARDS",
  subtitle: "The Best Gift for Car Enthusiasts",
  noSubtitleOnMobile: true,
  image:
    "https://content.speedwaymotors.com/HomePageImages/FEATURED-CARD-GIFTCARDS.png",
  button: {
    text: "Order Yours Now",
    url: "/giftcards",
  },
  appName: "HomePage",
  darkened: true,
};

const collectionCallout = {
  title: "OUR 1968 C10 BUILD",
  subtitle: "Giving an old truck new life",
  noSubtitleOnMobile: true,
  image:
    "https://content.speedwaymotors.com/HomePageImages/Masonry-c10.jpg",
  button: {
    text: "Follow the Build",
    url: "/pages/c10-build",
  },
  appName: "HomePage",
};

const trendingCallout = {
  title: "NEW AND TRENDING PRODUCTS",
  subtitle: "Take your project to the next level",
  noSubtitleOnMobile: true,
  image:
    "https://content.speedwaymotors.com/HomePageImages/FeatureCard-NewTrending.jpg",
  button: {
    text: "Shop Now",
    url: "/pages/newtrending",
  },
  appName: "HomePage",
};

const dealsCallout = {
  title: "SHOP DEALS",
  subtitle: "Build on a budget with discounted parts",
  noSubtitleOnMobile: true,
  image:
    "https://content.speedwaymotors.com/HomePageImages/FeatureCard-Deals.jpg",
  button: {
    text: "Check it out",
    url: "/deals",
  },
  appName: "HomePage",
};

const teamSpeedwayCallout = {
  title: "TEAM SPEEDWAY",
  subtitle: "Check us out at Goody Guys, July 1",
  noSubtitleOnMobile: true,
  image:
    "https://content.speedwaymotors.com/HomePageImages/FEATURED-CARD-GIFTCARDS.png",
  button: {
    text: "Check it out",
    url: "/featured/goodguys",
  },
  appName: "HomePage",
};

const gothamGarageCallout = {
  title: "SPEEDWAY MOTORS & GOTHAM GARAGE",
  subtitle: "Collaborate for a custom build",
  noSubtitleOnMobile: true,
  image:
    "https://content.speedwaymotors.com/HomePageImages/FEATURED-CARD-GIFTCARDS.png",
  button: {
    text: "Follow our build",
    url: "/pages/speedway-motors-gotham-garage-zephyr",
  },
  appName: "HomePage",
};

const neCarsAndCoffeeCallout = {
  title: "CARS AND COFFEE - LINCOLN",
  subtitle: "Cars. Coffee. Community.",
  supertitle: "EVENTS NEAR YOU",
  noSubtitleOnMobile: true,
  button: {
    url: "/pages/carsandcoffee",
    text: "See Details"
  },
  image:
    "https://content.speedwaymotors.com/HomePageImages/FEATURED-CARD-C&C-LNK.jpg",
  appName: "HomePage",
};

const rewardsSignUpCallout = {
  title: "MEMBERS GET AN EXTRA $10 OFF",
  subtitle: "Order this week and save instantly!",
  supertitle: "CUSTOMER APPRECIATION WEEK",
  noSubtitleOnMobile: true,
  button: {
    url: "/shop/2024-customer-appreciation-week~16777216-724369",
    text: "Sign Up Now"
  },
  image:
    "https://content.speedwaymotors.com/OtherImages/caw-masonry-bkg.jpg",
  appName: "HomePage",
};

const azCarsAndCoffeeCallout = {
  title: "CARS AND COFFEE - PHOENIX",
  subtitle: "Cars. Coffee. Community.",
  supertitle: "EVENTS NEAR YOU",
  noSubtitleOnMobile: true,
  button: {
    url: "/pages/carsandcoffee-AZ",
    text: "See Details"
  },
  image:
    "https://content.speedwaymotors.com/HomePageImages/FEATURED-CARD-C&C-PHX-Bluecar.jpg",
  appName: "HomePage",
};

export {
  streetCallout,
  raceCallout,
  engineCallout,
  shockCallout,
  catalogCallout,
  giftcardCallout,
  collectionCallout,
  trendingCallout,
  dealsCallout,
  teamSpeedwayCallout,
  gothamGarageCallout,
  neCarsAndCoffeeCallout, 
  azCarsAndCoffeeCallout,
  rewardsSignUpCallout
};
