import React, { useEffect, useRef, useState } from "react";

import { IsUserBot } from "@speedwaymotors/clutch/Utilities/CookieUtilties/UserCookieUtilities";
import AutoComplete from "Clutch/Atoms/Form/Elements/AutoComplete/AutoComplete";
import { useThemeStore } from "Clutch/Stores/ThemeStore";
import { useVehicleStore } from "Clutch/Stores/VehicleStore";
import { FetchEngine } from "Contexts/VehicleContext/CoreVehicleRequests";
import ClassNames from "classnames";

import styles from "../YmmSelector.module.scss";

const initialEngineState = {
  make: {
    value: null,
    label: null,
  },
  engineFamily: {
    value: null,
    label: null,
  },
  engine: {
    value: null,
    label: null,
  },
};

const EngineTab = ({ isEnabled }) => {
  const UpdateEnginesData = useVehicleStore((x) => x.UpdateEnginesData);
  const ClearSelectedVehicle = useVehicleStore((x) => x.ClearSelectedVehicle);
  const engines = useVehicleStore((x) => x.context.engines);
  const engineFamilies = useVehicleStore((x) => x.context.engineFamilies);
  const setIsLoading = useThemeStore((state) => state.setIsLoading);
  const [engineState, setEngineState] = useState(initialEngineState);

  useEffect(() => {
    const { make, engineFamily, engine } = engineState;
    async function fetchAllEngine() {
      var response = await FetchEngine(
        make.label,
        engineFamily?.value,
        "/graphql/trait"
      );
      if (response && make) {
        if (!engine.value) {
          UpdateEnginesData(
            response.engineLinks,
            engineFamily?.value ? "engines" : "engineFamilies"
          );
        }
      }
    }
    if (engine.value && !IsUserBot()) {
      let selectedModel = engines
        ? engines.find((x) => x.engineId === engine.value)
        : [];
      if (selectedModel?.url) {
        setIsLoading(true);
        ClearSelectedVehicle();
        window.location.href = selectedModel.url;
      }
    } else if (make.label && !IsUserBot()) {
      fetchAllEngine();
    }
  }, [isEnabled, engineState]);

  const familyRef = useRef();
  const engineRef = useRef();

  useEffect(() => {
    if (familyRef?.current) {
      familyRef.current.focus();
    }
  }, [engineState.make]);

  useEffect(() => {
    if (engineRef?.current) {
      engineRef.current.focus();
    }
  }, [engineFamilies]);

  return (
    <div className={styles.modalPanel}>
      <div className={ClassNames(styles.streetTab, styles.ymmContainer)}>
        <YmmDropdown
          placeholder={"Select a Make"}
          label={"Make"}
          className={styles.dropdownTitle}
          selectedValue={engineState.make}
          dropdownData={makes}
          onChange={(option) => {
            setEngineState((prevState) => ({
              ...initialEngineState,
              make: {
                value: option.value,
                label: option.label,
              },
              engineFamily: {
                value: null,
                label: null,
              },
            }));
          }}
        />
        <YmmDropdown
          placeholder={"Select an Engine Family"}
          label={"Engine Family"}
          selectedValue={engineState.engineFamily}
          dropdownData={engineFamilies}
          className={styles.dropdownTitle}
          onChange={(option) => {
            setEngineState((prevState) => ({
              ...initialEngineState,
              make: prevState.make,
              engineFamily: {
                value: parseInt(option.value, 10),
                label: option.label,
              },
            }));
          }}
          selectRef={familyRef}
        />
        <YmmDropdown
          placeholder={"Select an Engine"}
          label={"Engine"}
          selectedValue={engineState.model}
          className={styles.dropdownTitle}
          dropdownData={engines}
          onChange={(option) => {
            setEngineState((prevState) => ({
              ...prevState,
              make: prevState.make,
              engineFamily: prevState.engineFamily,
              engine: {
                value: parseInt(option.value, 10),
                label: option.label,
              },
            }));
          }}
          selectRef={engineRef}
        />
      </div>
    </div>
  );
};

const YmmDropdown = ({
  label,
  placeholder,
  selectedValue,
  dropdownData,
  onChange,
  selectRef,
}) => {
  let options = [];
  let emptyStyling = "";

  if (dropdownData?.length > 0) {
    dropdownData.forEach((data) => {
      options.push({ label: data.displayName, value: data.engineId });
    });
  } else {
    emptyStyling = styles.streetEmptyDropdown;
  }

  return (
    <div className={ClassNames(styles.ymmModalItem, emptyStyling)}>
      <AutoComplete
        options={options}
        onChange={(value) => onChange(value)}
        label={label}
        selectedValue={selectedValue}
        isDisabled={!dropdownData || dropdownData.length === 0}
        placeholder={placeholder}
        ref={selectRef}
        useNativeSelect={false}
      />
    </div>
  );
};
const makes = [
  { displayName: "AMC", engineId: 1 },
  { displayName: "Buick", engineId: 2 },
  { displayName: "Cadillac", engineId: 3 },
  { displayName: "Chevy", engineId: 4 },
  { displayName: "Chrysler", engineId: 5 },
  { displayName: "Ford", engineId: 6 },
  { displayName: "GM", engineId: 7 },
  { displayName: "GMC", engineId: 8 },
  { displayName: "Holden", engineId: 9 },
  { displayName: "Honda", engineId: 10 },
  { displayName: "International", engineId: 11 },
  { displayName: "Kawasaki", engineId: 12 },
  { displayName: "Oldsmobile", engineId: 13 },
  { displayName: "Pontiac", engineId: 14 },
  { displayName: "Studebaker", engineId: 15 },
  { displayName: "Suzuki", engineId: 16 },
  { displayName: "Yamaha", engineId: 17 },
];

export default EngineTab;
